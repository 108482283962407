var l = Object.defineProperty, h = Object.defineProperties;
var n = Object.getOwnPropertyDescriptors;
var m = Object.getOwnPropertySymbols;
var r = Object.prototype.hasOwnProperty, c = Object.prototype.propertyIsEnumerable;
var A = (Z, e, t) => e in Z ? l(Z, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : Z[e] = t, H = (Z, e) => {
  for (var t in e || (e = {}))
    r.call(e, t) && A(Z, t, e[t]);
  if (m)
    for (var t of m(e))
      c.call(e, t) && A(Z, t, e[t]);
  return Z;
}, M = (Z, e) => h(Z, n(e));
import a, { forwardRef as o } from "react";
import "../lib/context.es.js";
import p from "../lib/IconBase.es.js";
const E = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20ZM107.07,172h41.86A115.75,115.75,0,0,1,128,209.85,115.75,115.75,0,0,1,107.07,172Zm-5.66-24a142.55,142.55,0,0,1,0-40h53.18a142.55,142.55,0,0,1,0,40ZM44,128a83.49,83.49,0,0,1,2.43-20H77.22a164.54,164.54,0,0,0,0,40H46.43A83.49,83.49,0,0,1,44,128ZM148.93,84H107.07A115.75,115.75,0,0,1,128,46.15,115.75,115.75,0,0,1,148.93,84Zm29.85,24h30.79a83.52,83.52,0,0,1,0,40H178.78a164.54,164.54,0,0,0,0-40Zm20.74-24H174a148.59,148.59,0,0,0-13.95-33.63A84.5,84.5,0,0,1,199.52,84ZM96,50.37A148.59,148.59,0,0,0,82,84H56.48A84.5,84.5,0,0,1,96,50.37ZM56.48,172H82a148.59,148.59,0,0,0,14,33.63A84.5,84.5,0,0,1,56.48,172Zm103.57,33.63A148.59,148.59,0,0,0,174,172h25.52A84.5,84.5,0,0,1,160.05,205.63Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z", opacity: "0.2" }), /* @__PURE__ */ a.createElement("path", { d: "M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM101.63,168h52.74C149,186.34,140,202.87,128,215.89,116,202.87,107,186.34,101.63,168ZM98,152a145.72,145.72,0,0,1,0-48h60a145.72,145.72,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.79a161.79,161.79,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154.37,88H101.63C107,69.66,116,53.13,128,40.11,140,53.13,149,69.66,154.37,88Zm19.84,16h38.46a88.15,88.15,0,0,1,0,48H174.21a161.79,161.79,0,0,0,0-48Zm32.16-16H170.94a142.39,142.39,0,0,0-20.26-45A88.37,88.37,0,0,1,206.37,88ZM105.32,43A142.39,142.39,0,0,0,85.06,88H49.63A88.37,88.37,0,0,1,105.32,43ZM49.63,168H85.06a142.39,142.39,0,0,0,20.26,45A88.37,88.37,0,0,1,49.63,168Zm101.05,45a142.39,142.39,0,0,0,20.26-45h35.43A88.37,88.37,0,0,1,150.68,213Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm78.37,64H170.94a142.39,142.39,0,0,0-20.26-45A88.37,88.37,0,0,1,206.37,88ZM128,40.11c12,13,21,29.55,26.37,47.89H101.63C107,69.66,116,53.13,128,40.11ZM96,128a145.29,145.29,0,0,1,2-24h60a145.72,145.72,0,0,1,0,48H98A145.29,145.29,0,0,1,96,128Zm5.63,40h52.74C149,186.34,140,202.87,128,215.89,116,202.87,107,186.34,101.63,168Zm49.05,45a142.39,142.39,0,0,0,20.26-45h35.43A88.37,88.37,0,0,1,150.68,213Zm23.53-61a161.79,161.79,0,0,0,0-48h38.46a88.15,88.15,0,0,1,0,48Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,26A102,102,0,1,0,230,128,102.12,102.12,0,0,0,128,26Zm81.57,64H169.39a139.15,139.15,0,0,0-23.45-50.2A90.32,90.32,0,0,1,209.57,90ZM218,128a89.7,89.7,0,0,1-3.83,26H171.86a159,159,0,0,0,0-52h42.31A89.7,89.7,0,0,1,218,128Zm-90,90a1.75,1.75,0,0,1-1.32-.59C113.8,203.54,104.34,185.73,99,166h58c-5.34,19.73-14.8,37.54-27.68,51.41A1.75,1.75,0,0,1,128,218ZM96.31,154a147.48,147.48,0,0,1,0-52h63.38a147.48,147.48,0,0,1,0,52ZM38,128a89.7,89.7,0,0,1,3.83-26H84.14a159,159,0,0,0,0,52H41.83A89.7,89.7,0,0,1,38,128Zm90-90a1.75,1.75,0,0,1,1.32.59C142.2,52.46,151.66,70.27,157,90H99c5.34-19.73,14.8-37.54,27.68-51.41A1.75,1.75,0,0,1,128,38Zm-17.94,1.8A139.15,139.15,0,0,0,86.61,90H46.43A90.32,90.32,0,0,1,110.06,39.8ZM46.43,166H86.61a139.15,139.15,0,0,0,23.45,50.2A90.32,90.32,0,0,1,46.43,166Zm99.51,50.2A139.15,139.15,0,0,0,169.39,166h40.18A90.32,90.32,0,0,1,145.94,216.2Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM101.63,168h52.74C149,186.34,140,202.87,128,215.89,116,202.87,107,186.34,101.63,168ZM98,152a145.72,145.72,0,0,1,0-48h60a145.72,145.72,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.79a161.79,161.79,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154.37,88H101.63C107,69.66,116,53.13,128,40.11,140,53.13,149,69.66,154.37,88Zm19.84,16h38.46a88.15,88.15,0,0,1,0,48H174.21a161.79,161.79,0,0,0,0-48Zm32.16-16H170.94a142.39,142.39,0,0,0-20.26-45A88.37,88.37,0,0,1,206.37,88ZM105.32,43A142.39,142.39,0,0,0,85.06,88H49.63A88.37,88.37,0,0,1,105.32,43ZM49.63,168H85.06a142.39,142.39,0,0,0,20.26,45A88.37,88.37,0,0,1,49.63,168Zm101.05,45a142.39,142.39,0,0,0,20.26-45h35.43A88.37,88.37,0,0,1,150.68,213Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm87.64,128H169.49a157.43,157.43,0,0,0,0-56h46.15a92.09,92.09,0,0,1,0,56ZM128,220a3.76,3.76,0,0,1-2.78-1.23c-13.65-14.7-23.52-33.72-28.81-54.77h63.18c-5.29,21.05-15.16,40.07-28.81,54.77A3.76,3.76,0,0,1,128,220ZM94.65,156a149.25,149.25,0,0,1,0-56h66.7a149.25,149.25,0,0,1,0,56ZM128,36a3.76,3.76,0,0,1,2.78,1.23C144.43,51.93,154.3,71,159.59,92H96.41c5.29-21,15.16-40.07,28.81-54.77A3.76,3.76,0,0,1,128,36Zm84.66,56H167.81c-4.91-20.73-14-39.72-26.66-55.06A92.3,92.3,0,0,1,212.66,92ZM114.85,36.94C102.22,52.28,93.1,71.27,88.19,92H43.34A92.3,92.3,0,0,1,114.85,36.94ZM40.36,100H86.51a157.43,157.43,0,0,0,0,56H40.36a92.09,92.09,0,0,1,0-56Zm3,64H88.19c4.91,20.73,14,39.72,26.66,55.06A92.3,92.3,0,0,1,43.34,164Zm97.81,55.06c12.63-15.34,21.75-34.33,26.66-55.06h44.85A92.3,92.3,0,0,1,141.15,219.06Z" }))
  ]
]), d = o((Z, e) => /* @__PURE__ */ a.createElement(p, M(H({ ref: e }, Z), { weights: E })));
d.displayName = "Globe";
export {
  d as default
};
