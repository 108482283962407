var A = Object.defineProperty, M = Object.defineProperties;
var c = Object.getOwnPropertyDescriptors;
var m = Object.getOwnPropertySymbols;
var L = Object.prototype.hasOwnProperty, h = Object.prototype.propertyIsEnumerable;
var n = (l, e, t) => e in l ? A(l, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : l[e] = t, r = (l, e) => {
  for (var t in e || (e = {}))
    L.call(e, t) && n(l, t, e[t]);
  if (m)
    for (var t of m(e))
      h.call(e, t) && n(l, t, e[t]);
  return l;
}, Z = (l, e) => M(l, c(e));
import a, { forwardRef as o } from "react";
import "../lib/context.es.js";
import p from "../lib/IconBase.es.js";
const E = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M234.79,149.32,215.7,97.11A20,20,0,0,0,190.12,85.2l-61.31,22.22L139.7,45.18A20,20,0,0,0,123.55,22L68.87,12.31A19.94,19.94,0,0,0,45.76,28.55l-25,143.13A48,48,0,0,0,59.4,227.26a51.19,51.19,0,0,0,8.7.74H216a20,20,0,0,0,20-20V156.19A21.74,21.74,0,0,0,234.79,149.32ZM91,184.18a23.84,23.84,0,0,1-9.86,15.56,23.28,23.28,0,0,1-17.56,3.89,24,24,0,0,1-19.23-27.82L68.71,36.66,115.37,45,91,184.18Zm23.64,4.13,9.39-53.64,70.49-25.54,16.3,44.59-96.23,34.87C114.62,188.5,114.65,188.41,114.66,188.31ZM212,204H142.52L212,178.82ZM81.22,174.07l-1.4,8A12,12,0,0,1,68,192a12.35,12.35,0,0,1-2.08-.18,12,12,0,0,1-9.75-13.89l1.4-8a12,12,0,0,1,23.64,4.14Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M127.88,43.11l-25,143.14a35.71,35.71,0,0,1-41.34,29.2h0a36,36,0,0,1-28.95-41.71l25-143.13a8,8,0,0,1,9.19-6.49l54.67,9.73A8,8,0,0,1,127.88,43.11Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M80,180a12,12,0,1,1-12-12A12,12,0,0,1,80,180Zm152-23.81V208a16,16,0,0,1-16,16H68a46.36,46.36,0,0,1-7.94-.68,44,44,0,0,1-35.43-50.95l25-143.13a15.94,15.94,0,0,1,18.47-13L122.84,26a16,16,0,0,1,12.92,18.52l-12.08,69L191.49,89a16,16,0,0,1,20.45,9.52L231,150.69A18.35,18.35,0,0,1,232,156.19ZM95,184.87,120,41.74,65.46,32l-25,143.1A28,28,0,0,0,62.9,207.57,27.29,27.29,0,0,0,83.46,203,27.84,27.84,0,0,0,95,184.87ZM108.78,195,216,156.11,196.92,104,120.5,131.7l-9.78,55.92A44.63,44.63,0,0,1,108.78,195ZM216,173.12,119.74,208H216Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M232,155.91a16,16,0,0,0-1-5.22L211.94,98.48A16,16,0,0,0,191.49,89l-67.81,24.57,12.08-69A16,16,0,0,0,122.84,26L68.17,16.25a15.94,15.94,0,0,0-18.47,13l-25,143.12A43.82,43.82,0,0,0,67.78,224H216a16,16,0,0,0,16-16ZM68,196a16,16,0,1,1,16-16A16,16,0,0,1,68,196Zm42.72-8.38,9.78-55.92L196.92,104,216,156.11,108.78,195A44.89,44.89,0,0,0,110.72,187.62ZM216,208H119.74L216,173.11Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M78,180a10,10,0,1,1-10-10A10,10,0,0,1,78,180Zm152-23.81V208a14,14,0,0,1-14,14H68a44.18,44.18,0,0,1-7.58-.65,42,42,0,0,1-33.81-48.64l25-143.13A13.94,13.94,0,0,1,67.82,18.22l54.67,9.72a14,14,0,0,1,11.3,16.21l-12.67,72.44,71-25.75a14,14,0,0,1,17.89,8.32l19.09,52.22A15.66,15.66,0,0,1,230,156.19Zm-133.07,29L122,42.08a2,2,0,0,0-1.58-2.32L65.72,30l-.34,0a1.84,1.84,0,0,0-1.07.35,2,2,0,0,0-.82,1.3l-25,143.13a30,30,0,0,0,24.09,34.76,29.25,29.25,0,0,0,22-4.89,29.81,29.81,0,0,0,12.33-19.44Zm8.25,13.17L216.71,158a2,2,0,0,0,1.11-1,1.86,1.86,0,0,0,.06-1.46l-19.09-52.21a2,2,0,0,0-2.53-1.17l-77.53,28.09-10,57.07A41.9,41.9,0,0,1,105.18,198.38ZM218,170.27,108.35,210H216a2,2,0,0,0,2-2Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M80,180a12,12,0,1,1-12-12A12,12,0,0,1,80,180Zm152-23.81V208a16,16,0,0,1-16,16H68a46.36,46.36,0,0,1-7.94-.68,44,44,0,0,1-35.43-50.95l25-143.13a15.94,15.94,0,0,1,18.47-13L122.84,26a16,16,0,0,1,12.92,18.52l-12.08,69L191.49,89a16,16,0,0,1,20.45,9.52L231,150.69A18.35,18.35,0,0,1,232,156.19ZM95,184.87,120,41.74,65.46,32l-25,143.1A28,28,0,0,0,62.9,207.57,27.29,27.29,0,0,0,83.46,203,27.84,27.84,0,0,0,95,184.87ZM108.78,195,216,156.11,196.92,104,120.5,131.7l-9.78,55.92A44.63,44.63,0,0,1,108.78,195ZM216,173.12,119.74,208H216Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M227.27,152.07,208.19,99.85a12,12,0,0,0-15.34-7.13l-74.3,26.92,13.27-75.83a12,12,0,0,0-9.68-13.9L67.47,20.19a11.75,11.75,0,0,0-8.89,2,11.9,11.9,0,0,0-4.94,7.77l-25,143.13A40,40,0,0,0,60.8,219.39,42.68,42.68,0,0,0,68,220H216a12,12,0,0,0,12-12V156.19A14,14,0,0,0,227.27,152.07Zm-31.7-51.83a4,4,0,0,1,5.1,2.36l19.09,52.21a3.9,3.9,0,0,1-.13,3,3.94,3.94,0,0,1-2.24,2L100.78,202.11a40,40,0,0,0,6-15.17L117,128.73ZM85.75,206.29a31.25,31.25,0,0,1-23.55,5.22,32,32,0,0,1-25.71-37.08l25-143.13a4,4,0,0,1,1.64-2.59A3.85,3.85,0,0,1,65.38,28a4,4,0,0,1,.69.06l54.67,9.73a4,4,0,0,1,3.2,4.64l-25,143.13h0A31.79,31.79,0,0,1,85.75,206.29ZM220,208a4,4,0,0,1-4,4H97l123-44.59ZM76,180a8,8,0,1,1-8-8A8,8,0,0,1,76,180Z" }))
  ]
]), d = o((l, e) => /* @__PURE__ */ a.createElement(p, Z(r({ ref: e }, l), { weights: E })));
d.displayName = "Swatches";
export {
  d as default
};
