var c = Object.defineProperty, Z = Object.defineProperties;
var h = Object.getOwnPropertyDescriptors;
var m = Object.getOwnPropertySymbols;
var o = Object.prototype.hasOwnProperty, p = Object.prototype.propertyIsEnumerable;
var n = (l, e, t) => e in l ? c(l, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : l[e] = t, r = (l, e) => {
  for (var t in e || (e = {}))
    o.call(e, t) && n(l, t, e[t]);
  if (m)
    for (var t of m(e))
      p.call(e, t) && n(l, t, e[t]);
  return l;
}, A = (l, e) => Z(l, h(e));
import a, { forwardRef as E } from "react";
import "../lib/context.es.js";
import M from "../lib/IconBase.es.js";
const d = /* @__PURE__ */ new Map([
  [
    "bold",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M236.06,187.69A84,84,0,0,0,172.29,68.9,84,84,0,1,0,19.94,139.69L12.7,165.05A18,18,0,0,0,35,187.3l25.36-7.24a84.27,84.27,0,0,0,23.36,7,84.05,84.05,0,0,0,112,41l25.36,7.24a18,18,0,0,0,22.25-22.25ZM28.36,164.22Zm33.17-9a12,12,0,0,0-3.3.46l-19.49,5.57,5.57-19.49a12,12,0,0,0-1-9.05,60,60,0,1,1,24,24A11.91,11.91,0,0,0,61.53,155.23Zm150.16,34.54,5.57,19.49-19.49-5.57a12,12,0,0,0-9.05,1A60.06,60.06,0,0,1,111,186.63a83.93,83.93,0,0,0,68.55-91.37,60,60,0,0,1,33.16,85.46A12,12,0,0,0,211.69,189.77Z" }))
  ],
  [
    "duotone",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement(
      "path",
      {
        d: "M231.76,216.34a6,6,0,0,1-7.42,7.42l-29.87-8.53A72.05,72.05,0,0,1,92.06,175.89h0c1.3.07,2.61.11,3.93.11a72,72,0,0,0,67.93-95.88h0a72,72,0,0,1,59.29,106.36Z",
        opacity: "0.2"
      }
    ), /* @__PURE__ */ a.createElement("path", { d: "M231.79,187.33A80,80,0,0,0,169.57,72.59,80,80,0,1,0,24.21,139.33l-7.66,26.82a14,14,0,0,0,17.3,17.3l26.82-7.66a80.15,80.15,0,0,0,25.75,7.63,80,80,0,0,0,108.91,40.37l26.82,7.66a14,14,0,0,0,17.3-17.3ZM61.53,159.23a8.22,8.22,0,0,0-2.2.3l-26.41,7.55,7.55-26.41a8,8,0,0,0-.68-6,63.95,63.95,0,1,1,25.57,25.57A7.94,7.94,0,0,0,61.53,159.23Zm154,29.44,7.55,26.41-26.41-7.55a8,8,0,0,0-6,.68,64.06,64.06,0,0,1-86.32-24.64A79.93,79.93,0,0,0,174.7,89.71a64,64,0,0,1,41.51,92.93A8,8,0,0,0,215.53,188.67Z" }))
  ],
  [
    "fill",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M231.79,187.33A80,80,0,0,0,169.57,72.59,80,80,0,1,0,24.21,139.33l-7.66,26.82a14,14,0,0,0,17.3,17.3l26.82-7.66a80.15,80.15,0,0,0,25.75,7.63,80,80,0,0,0,108.91,40.37l26.82,7.66a14,14,0,0,0,17.3-17.3Zm-16.26,1.34,7.55,26.41-26.41-7.55a8,8,0,0,0-6,.68,64.06,64.06,0,0,1-86.32-24.64A79.93,79.93,0,0,0,174.7,89.71a64,64,0,0,1,41.51,92.93A8,8,0,0,0,215.53,188.67Z" }))
  ],
  [
    "light",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M229.66,187.13a78,78,0,0,0-61.5-112.71A78,78,0,1,0,26.34,139.13L18.47,166.7A12,12,0,0,0,33.3,181.53l27.57-7.87a78.25,78.25,0,0,0,26.94,7.9,78.05,78.05,0,0,0,107.32,40.1l27.57,7.87a12,12,0,0,0,14.83-14.83ZM61.53,161.23a5.82,5.82,0,0,0-1.65.23L30,170l8.53-29.87a6,6,0,0,0-.5-4.53A66,66,0,1,1,64.41,162,6.1,6.1,0,0,0,61.53,161.23Zm155.93,26.89L226,218l-29.87-8.53a6,6,0,0,0-4.53.5,66,66,0,0,1-90.48-28.15,77.92,77.92,0,0,0,71-94.68A66,66,0,0,1,218,183.59,6,6,0,0,0,217.46,188.12Z" }))
  ],
  [
    "regular",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M231.79,187.33A80,80,0,0,0,169.57,72.59,80,80,0,1,0,24.21,139.33l-7.66,26.82a14,14,0,0,0,17.3,17.3l26.82-7.66a80.15,80.15,0,0,0,25.75,7.63,80,80,0,0,0,108.91,40.37l26.82,7.66a14,14,0,0,0,17.3-17.3ZM61.53,159.23a8.22,8.22,0,0,0-2.2.3l-26.41,7.55,7.55-26.41a8,8,0,0,0-.68-6,63.95,63.95,0,1,1,25.57,25.57A7.94,7.94,0,0,0,61.53,159.23Zm154,29.44,7.55,26.41-26.41-7.55a8,8,0,0,0-6,.68,64.06,64.06,0,0,1-86.32-24.64A79.93,79.93,0,0,0,174.7,89.71a64,64,0,0,1,41.51,92.93A8,8,0,0,0,215.53,188.67Z" }))
  ],
  [
    "thin",
    /* @__PURE__ */ a.createElement(a.Fragment, null, /* @__PURE__ */ a.createElement("path", { d: "M235.6,215.24l-8.09-28.32A76,76,0,0,0,166.76,76.31,76,76,0,1,0,28.49,138.92L20.4,167.24A10,10,0,0,0,30,180a10.08,10.08,0,0,0,2.8-.4l28.32-8.09a76,76,0,0,0,28.13,8.18,76,76,0,0,0,105.71,39.82l28.32,8.09a10.08,10.08,0,0,0,2.8.4,10,10,0,0,0,9.56-12.76Zm-174.07-52a3.75,3.75,0,0,0-1.1.16l-29.87,8.53h0a2,2,0,0,1-2.47-2.47l8.53-29.87a4,4,0,0,0-.33-3,68,68,0,1,1,27.16,27.16A4,4,0,0,0,61.53,163.22ZM227.4,219.4a2,2,0,0,1-2,.51l-29.87-8.53a4,4,0,0,0-3,.33A68,68,0,0,1,98,180a76,76,0,0,0,71.5-95.28,68,68,0,0,1,50.21,99.88,4,4,0,0,0-.33,3l8.53,29.87A2,2,0,0,1,227.4,219.4Z" }))
  ]
]), i = E((l, e) => /* @__PURE__ */ a.createElement(M, A(r({ ref: e }, l), { weights: d })));
i.displayName = "ChatsCircle";
export {
  i as default
};
